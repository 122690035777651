import i18n from '@/i18n';

const CONFIG_TABLE_SNS = {
    headers: [
        { label: i18n.t('pageCampaign.tab7.applicationType'), required: true, width: '250px' },
        { label: i18n.t('pageCampaign.tab7.startCP'), required: false, width: '70px' },
        { label: i18n.t('pageCampaign.tab7.endCP'), required: false, width: '70px' },
        { label: i18n.t('pageCampaign.tab7.increase'), required: false, width: '70px' }
    ]
};

const CONFIG_ADVERTISING_OPERATION = {
    error: false,
    isRemove: false,
    loading: false,
    trackBy: 'id',
    label: 'value'
};

const CONFIG_TOTAL = {
    isNumber: true,
    classCustom: 'text-center'
};

const CONFIG_EMBED_TAGS = {
    error: false,
    isRemove: false,
    loading: false,
    trackBy: 'id',
    label: 'value'
};

const CONFIG_MANAGEMENT_SCREEN = {
    isMaxlength: false,
    error: false,
    erroField: i18n.t('pageCampaign.tab7.managementScreen')
};

const LIST_GIVE_AWAY_RELATED = [
    {
        id: 1,
        value: 'なし',
        isBoolean: false
    },
    {
        id: 2,
        value: 'あり',
        isBoolean: true
    }
];

export { CONFIG_TABLE_SNS, CONFIG_ADVERTISING_OPERATION, CONFIG_TOTAL, CONFIG_EMBED_TAGS, CONFIG_MANAGEMENT_SCREEN, LIST_GIVE_AWAY_RELATED };
