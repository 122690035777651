import i18n from '@/i18n';
import moment from 'moment';
import { cleanConfigFormData } from '@/utils/format';

function initObjectTypeTitle(data = null, type = 'watch') {
    let objTypeTitle = {
        master_id: null,
        configFieldSelectTypeTitle: {
            trackBy: 'id',
            label: 'value',
            errorField: i18n.t('pageCampaign.tabPrize.type'),
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: []
        },
        note: {
            electronic_money_point_info: '',
            configFieldElectronic: {
                isNumber: false,
                isMaxLength: false,
                error: false,
                errorField: '',
                errorCustomMess: '',
                valueSync: '',
                typesValidate: ['max255']
            }
        },
        children_data: [],
        listCheckedLv1: [],
        listCheckedLv2: []
    };
    if (!data) {
        objTypeTitle = {
            ...objTypeTitle
        };
    } else {
        const conditionData = data?.children_data ?? data?.data;
        objTypeTitle = {
            ...objTypeTitle,
            master_id: {
                id: data?.master_id?.id ?? data?.id ?? null,
                value: data?.master_id?.value ?? data?.value ?? ''
            },
            note: {
                electronic_money_point_info: data?.note?.electronic_money_point_info ?? '',
                configFieldElectronic: {
                    ...objTypeTitle.note.configFieldElectronic,
                    errorField: data?.value ?? ''
                }
            },
            children_data: type == 'watch' ? handleChildrenDataRecursion(conditionData, 'init') : [],
            listCheckedLv1: type == 'watch' ? data?.children_data?.map((item) => item.id) : [],
            listCheckedLv2: type == 'watch' ? data?.children_data?.flatMap((item) => item.children_data.flatMap((ot) => ot.id)) : []
        };
    }
    return objTypeTitle;
}

function initObjectPize(data = null) {
    let object = {
        isActivePrize: true,
        id: null,
        title: '',
        is_high_coupon_value: false,
        types: initObjectTypeTitle(),
        configTitle: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.titlePrize')
        },
        number_of_win_prize: 0,
        number_of_people_drawing_lots: 0,
        arrayNameAndQuantity: [
            {
                name: '',
                configPrizeName: {
                    errorCustomMess: '',
                    isRequired: false,
                    isMaxLength: false,
                    error: false,
                    errorField: i18n.t('pageCampaign.tabPrize.namePrize')
                },
                quantity: 0,
                quantity_for_event_prize: 0,
                scheduled_arrived_at: '',
                warehousing_date: '',
                warehousing_quantity: 0,
                configWarehousingQuantity: {
                    isNumber: true,
                    maxlength: 10,
                    error: false,
                    errorCustomMess: '',
                    classCustom: 'text-center'
                },
                estimated_remaining_quantity: 0,
                configEstimatedRemainingQuantity: {
                    isNumber: true,
                    maxlength: 10,
                    error: false,
                    errorCustomMess: '',
                    hasWarning: false,
                    classCustom: 'text-center'
                },
                memo: '',
                configMemo: {
                    errorCustomMess: '',
                    isRequired: false,
                    isMaxLength: false,
                    error: false,
                    errorField: i18n.t('pageCampaign.tabPrize.memo')
                },
                detail_information: {
                    delivery_company: '',
                    configDeliveryCompany: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.shippingCompany')
                    },
                    tracking_number: '',
                    configTrakingNumber: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.trackingNumber')
                    },
                    number_of_boxes_to_be_stocked: '',
                    configNumberOfBoxesToBeStocked: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.expectedNumberOfBoxesInStock')
                    },
                    expiration_date: '',
                    configExpirationDate: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.expirationDate')
                    },
                    storage_warehouse: null,
                    configStorageWarehouse: {
                        error: false,
                        isRemove: false,
                        loading: false,
                        trackBy: 'id',
                        label: 'value',
                        openDirection: 'top'
                    },
                    locator_number: '',
                    configLocatorNumber: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.locatorNumber')
                    }
                },
                remaining_quantity: 0,
                configRemainingQuantity: {
                    isNumber: true,
                    maxlength: 10,
                    error: false,
                    errorCustomMess: '',
                    hasWarning: false
                },
                remaining_response: {
                    prize_less_status: null,
                    configReturnPrizeStatus: {
                        error: false,
                        isRemove: false,
                        loading: false,
                        trackBy: 'id',
                        label: 'value'
                    },
                    reconfirmation_date: null,
                    reconfirmation_date_y: null,
                    reconfirmation_date_m: null,
                    reconfirmation_date_d: null,
                    configReconfirmationDate: {
                        error: false,
                        errorText: ''
                    },
                    prize_refund_instructions: '',
                    configPrizeRefundInstructions: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.prizeResponseInstructions')
                    },
                    preserve_fee: '',
                    configPreserveFee: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.storageFee')
                    },
                    date_of_preserve: null,
                    date_of_preserve_y: null,
                    date_of_preserve_m: null,
                    date_of_preserve_d: null,
                    configDateOfPreserve: {
                        error: false,
                        errorText: ''
                    },
                    reference: '',
                    configReference: {
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.remarks')
                    }
                }
            }
        ],
        delivery_date_schedule: null,
        delivery_date: null,
        delivery_quantity: 0,
        configDeliveryQuantity: {
            isNumber: true,
            maxlength: 10,
            error: false,
            errorCustomMess: '',
            classCustom: 'text-center'
        },
        return_prize_items_count: 0,
        return_prize_items: []
    };
    if (!data) {
        object = {
            ...object
        };
    } else {
        object = {
            ...object,
            id: data?.id ?? null,
            title: data?.title ?? '',
            is_high_coupon_value: data?.is_high_coupon_value == 0 ? false : true,
            types: initObjectTypeTitle(data?.types[0] ?? null, 'watch'),
            number_of_win_prize: data?.number_of_win_prize ?? 0,
            number_of_people_drawing_lots: data?.number_of_people_drawing_lots ?? 0,
            arrayNameAndQuantity: data?.prizes.map((itemPz) => {
                return {
                    id: itemPz.id ?? null,
                    name: itemPz?.name ?? '',
                    configPrizeName: {
                        errorCustomMess: '',
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.namePrize')
                    },
                    quantity: itemPz?.quantity ?? 0,
                    quantity_for_event_prize: itemPz?.quantity_for_event_prize ?? 0,
                    scheduled_arrived_at: itemPz?.scheduled_arrived_at ?? '',
                    warehousing_date: itemPz?.warehousing_date ?? '',
                    warehousing_quantity: itemPz?.warehousing_quantity ?? 0,
                    configWarehousingQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorCustomMess: '',
                        classCustom: 'text-center'
                    },
                    estimated_remaining_quantity: itemPz?.estimated_remaining_quantity ?? 0,
                    configEstimatedRemainingQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorCustomMess: '',
                        hasWarning: false,
                        classCustom: 'text-center'
                    },
                    memo: itemPz?.memo ?? '',
                    configMemo: {
                        errorCustomMess: '',
                        isRequired: false,
                        isMaxLength: false,
                        error: false,
                        errorField: i18n.t('pageCampaign.tabPrize.memo')
                    },
                    detail_information: {
                        delivery_company: itemPz?.detail_information?.delivery_company ?? '',
                        configDeliveryCompany: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.shippingCompany')
                        },
                        tracking_number: itemPz?.detail_information?.tracking_number ?? '',
                        configTrakingNumber: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.trackingNumber')
                        },
                        number_of_boxes_to_be_stocked: itemPz?.detail_information?.number_of_boxes_to_be_stocked ?? '',
                        configNumberOfBoxesToBeStocked: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.expectedNumberOfBoxesInStock')
                        },
                        expiration_date: itemPz?.detail_information?.expiration_date ?? '',
                        configExpirationDate: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.expirationDate')
                        },
                        storage_warehouse: itemPz?.detail_information?.storage_warehouse ?? null,
                        configStorageWarehouse: {
                            error: false,
                            isRemove: false,
                            loading: false,
                            trackBy: 'id',
                            label: 'value',
                            openDirection: 'top'
                        },
                        locator_number: itemPz?.detail_information?.locator_number ?? '',
                        configLocatorNumber: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.locatorNumber')
                        }
                    },
                    remaining_quantity: itemPz?.remaining_quantity ?? 0,
                    configRemainingQuantity: {
                        isNumber: true,
                        maxlength: 10,
                        error: false,
                        errorCustomMess: '',
                        hasWarning: false
                    },
                    remaining_response: {
                        prize_less_status: itemPz?.remaining_response?.prize_less_status?.id ? itemPz?.remaining_response?.prize_less_status : null,
                        configReturnPrizeStatus: {
                            error: false,
                            isRemove: false,
                            loading: false,
                            trackBy: 'id',
                            label: 'value'
                        },
                        reconfirmation_date: itemPz?.remaining_response?.reconfirmation_date ?? null,
                        reconfirmation_date_y: itemPz?.remaining_response?.reconfirmation_date
                            ? moment(itemPz?.remaining_response?.reconfirmation_date).year().toString()
                            : null,
                        reconfirmation_date_m: itemPz?.remaining_response?.reconfirmation_date
                            ? (moment(itemPz?.remaining_response?.reconfirmation_date).month() + 1).toString()
                            : null,
                        reconfirmation_date_d: itemPz?.remaining_response?.reconfirmation_date
                            ? moment(itemPz?.remaining_response?.reconfirmation_date).date().toString()
                            : null,
                        configReconfirmationDate: {
                            error: false,
                            errorText: ''
                        },
                        prize_refund_instructions: itemPz?.remaining_response?.prize_refund_instructions ?? '',
                        configPrizeRefundInstructions: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.prizeResponseInstructions')
                        },
                        preserve_fee: itemPz?.remaining_response?.preserve_fee ?? '',
                        configPreserveFee: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.storageFee')
                        },
                        date_of_preserve: itemPz?.remaining_response?.date_of_preserve ?? null,
                        date_of_preserve_y: itemPz?.remaining_response?.date_of_preserve
                            ? moment(itemPz?.remaining_response?.date_of_preserve).year().toString()
                            : null,
                        date_of_preserve_m: itemPz?.remaining_response?.date_of_preserve
                            ? (moment(itemPz?.remaining_response?.date_of_preserve).month() + 1).toString()
                            : null,
                        date_of_preserve_d: itemPz?.remaining_response?.date_of_preserve
                            ? moment(itemPz?.remaining_response?.date_of_preserve).date().toString()
                            : null,
                        configDateOfPreserve: {
                            error: false,
                            errorText: ''
                        },
                        reference: itemPz?.remaining_response?.reference ?? '',
                        configReference: {
                            isRequired: false,
                            isMaxLength: false,
                            error: false,
                            errorField: i18n.t('pageCampaign.tabPrize.remarks')
                        }
                    }
                };
            }),
            delivery_date_schedule: data?.delivery_date_schedule ?? null,
            delivery_date: data?.delivery_date ?? null,
            delivery_quantity: data?.delivery_quantity ?? 0,
            return_prize_items: data.return_prize_items.length > 0 ? data.return_prize_items.map((itemRT) => initObjectReturnPrizeItem(itemRT)) : [],
            return_prize_items_count: data.return_prize_items.length > 0 ? data.return_prize_items.length : 0
        };
    }
    return object;
}

function initObjectReturnPrizeItem(data = null) {
    let returnPrizeItem = {
        id: null,
        return_date: null,
        configReturnDate: {
            isRequired: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.returnDate'),
            errorText: ''
        },
        shipping_company: null,
        configShippingCompany: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.shippingCompanyReturn')
        },
        tracking_number: '',
        configTrakingNumber: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.trackingNumber')
        },
        reason: '',
        configReason: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.reasonForReturn')
        },
        province: '',
        confingProvince: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.prefectures')
        },
        shipping_return_fee: '',
        configShippingReturnFee: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.returnShippingFee')
        },
        return_status: null,
        configReturnStatus: {
            error: false,
            isRemove: false,
            loading: false,
            trackBy: 'id',
            label: 'value',
            errorField: i18n.t('pageCampaign.tabPrize.returnStatus')
        },
        reconfirmation_schedule: null,
        configReconfirmationSchedule: {
            error: false,
            errorText: ''
        },
        instruction: '',
        configInstruction: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.instructionsForReturningPrizes')
        },
        storage_code: '',
        configStorageCode: {
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.locatorNumber')
        },
        note: ''
    };
    if (!data) {
        returnPrizeItem = {
            ...returnPrizeItem
        };
    } else {
        returnPrizeItem = {
            ...returnPrizeItem,
            id: data?.id ?? null,
            return_date: data?.return_date ?? null,
            shipping_company: data?.shipping_company ?? null,
            tracking_number: data?.tracking_number ?? '',
            reason: data?.reason ?? '',
            province: data?.province ?? '',
            shipping_return_fee: data?.shipping_return_fee ?? '',
            return_status: data?.return_status?.id ? data?.return_status : null,
            reconfirmation_schedule: data?.reconfirmation_schedule ?? null,
            instruction: data?.instruction ?? '',
            storage_code: data?.storage_code ?? '',
            note: data?.note ?? ''
        };
    }
    return returnPrizeItem;
}

function handleValidateArrayNameAndQuantity(arrayObjectPizes) {
    let check = true;
    arrayObjectPizes.forEach((item) => {
        if (!item.title) {
            item.configTitle.isRequired = true;
            item.configTitle.error = true;
            item.configTitle.isMaxLength = false;
            check = false;
        } else {
            if (item.title.length > 255) {
                item.configTitle.isRequired = false;
                item.configTitle.isMaxLength = true;
                item.configTitle.error = true;
                check = false;
            }
        }
        const prizeNameSet = new Set();
        item.arrayNameAndQuantity.forEach((itemChild) => {
            if (!itemChild.name) {
                itemChild.configPrizeName.error = true;
                itemChild.configPrizeName.isRequired = true;
                itemChild.configPrizeName.isMaxLength = false;
                itemChild.configPrizeName.errorCustomMess = '';
                check = false;
            } else {
                if (itemChild.name.length > 255) {
                    itemChild.configPrizeName.error = true;
                    itemChild.configPrizeName.isMaxLength = true;
                    itemChild.configPrizeName.isRequired = false;
                    itemChild.configPrizeName.errorCustomMess = '';
                    check = false;
                } else {
                    if (prizeNameSet.has(itemChild.name.trim().toString().toLowerCase())) {
                        itemChild.configPrizeName.error = true;
                        itemChild.configPrizeName.errorCustomMess = `${i18n.t('pageCampaign.tabPrize.namePrize')}がすでに登録されています。`;
                        itemChild.configPrizeName.isRequired = false;
                        itemChild.configPrizeName.isMaxLength = false;
                        check = false;
                    } else {
                        prizeNameSet.add(itemChild.name.trim().toString().toLowerCase());
                    }
                }
            }

            if (itemChild.memo) {
                if (itemChild.memo.length > 255) {
                    itemChild.configMemo.error = true;
                    itemChild.configMemo.isMaxLength = true;
                    check = false;
                } else {
                    itemChild.configMemo.error = false;
                    itemChild.configMemo.isMaxLength = false;
                }
            }
        });
    });
    return check;
}

function handleMapDataPrizes(arrayObjectPizes, type = null) {
    let mapData = arrayObjectPizes.map((item) => {
        const listPrizes = type === 'copy' ? item.prizes : item.arrayNameAndQuantity;
        const convertTypeObj = type === 'copy' ? item.types[0] : item.types;
        return {
            ...(item.id !== null && {
                id: item.id
            }),
            is_high_coupon_value: item?.is_high_coupon_value == 0 ? false : true,
            types: handleMapTypesTitle(convertTypeObj),
            title: item.title,
            number_of_win_prize: Number(item.number_of_win_prize) ?? 0,
            number_of_people_drawing_lots: Number(item.number_of_people_drawing_lots) ?? 0,
            prizes: listPrizes.map((itemChild) => {
                return {
                    ...(itemChild.id !== null && {
                        id: itemChild.id
                    }),
                    name: itemChild.name,
                    quantity: Number(itemChild.quantity),
                    quantity_for_event_prize: Number(itemChild.quantity_for_event_prize),
                    scheduled_arrived_at: itemChild.scheduled_arrived_at,
                    warehousing_date: itemChild.warehousing_date,
                    warehousing_quantity: Number(itemChild.warehousing_quantity),
                    estimated_remaining_quantity: Number(itemChild.estimated_remaining_quantity),
                    memo: itemChild.memo,
                    detail_information: {
                        delivery_company: itemChild.detail_information.delivery_company,
                        tracking_number: itemChild.detail_information.tracking_number,
                        number_of_boxes_to_be_stocked: itemChild.detail_information.number_of_boxes_to_be_stocked,
                        expiration_date: itemChild.detail_information.expiration_date,
                        storage_warehouse: itemChild.detail_information.storage_warehouse,
                        locator_number: itemChild.detail_information.locator_number
                    },
                    remaining_quantity: Number(itemChild.remaining_quantity),
                    remaining_response: {
                        prize_less_status: {
                            id: itemChild.remaining_response.prize_less_status?.id ?? null
                        },
                        reconfirmation_date: itemChild.remaining_response.reconfirmation_date,
                        prize_refund_instructions: itemChild.remaining_response.prize_refund_instructions,
                        preserve_fee: itemChild.remaining_response.preserve_fee,
                        date_of_preserve: itemChild.remaining_response.date_of_preserve,
                        reference: itemChild.remaining_response.reference
                    }
                };
            }),
            delivery_date_schedule: item.delivery_date_schedule,
            delivery_date: item.delivery_date,
            delivery_quantity: Number(item.delivery_quantity),
            return_prize_items: item.return_prize_items.map((itemRT) => {
                return {
                    ...(itemRT.id !== null && {
                        id: itemRT.id
                    }),
                    return_date: itemRT.return_date,
                    shipping_company: itemRT.shipping_company,
                    tracking_number: itemRT.tracking_number,
                    reason: itemRT.reason,
                    province: itemRT.province,
                    shipping_return_fee: itemRT.shipping_return_fee,
                    return_status: {
                        id: itemRT.return_status?.id ?? null
                    },
                    reconfirmation_schedule: itemRT.reconfirmation_schedule,
                    instruction: itemRT.instruction,
                    storage_code: itemRT.storage_code,
                    note: itemRT.note
                };
            })
        };
    });
    return mapData ?? [];
}

function handleMapTypesTitle(itemType) {
    let dataAfterClean = cleanConfigFormData(itemType);
    if (!dataAfterClean?.master_id?.id && !dataAfterClean?.id) return null;
    dataAfterClean = {
        master_id: dataAfterClean?.master_id?.id ?? dataAfterClean?.id ?? null,
        children_data: handleChildrenDataRecursion(dataAfterClean.children_data, null)
    };
    return dataAfterClean;
}

function handleChildrenDataRecursion(dataAfterClean, type = null) {
    if (type == 'init') {
        return dataAfterClean.map((dataChil) => {
            return {
                master_id: { id: dataChil?.id ?? null, value: dataChil?.value ?? '' },
                note: {
                    electronic_money_point_info: dataChil?.note?.electronic_money_point_info ?? '',
                    configFieldElectronic: {
                        isNumber: false,
                        isMaxLength: false,
                        error: false,
                        errorField: dataChil.value,
                        errorCustomMess: '',
                        valueSync: '',
                        typesValidate: ['max255']
                    }
                },
                children_data: handleChildrenDataRecursion(dataChil?.children_data, 'init')
            };
        });
    } else {
        return dataAfterClean.map((ol) => {
            return {
                master_id: ol?.master_id?.id ?? ol?.id ?? null,
                note: {
                    electronic_money_point_info: ol?.note?.electronic_money_point_info ?? ''
                },
                children_data: handleChildrenDataRecursion(ol.children_data, null)
            };
        });
    }
}

function handleExcuteMath(params) {
    for (const item of params.arrayNameAndQuantity) {
        // quantity_for_event_prize = quantity * number_of_win_prize
        if (!params.number_of_win_prize) {
            item.quantity_for_event_prize = 0;
            params.number_of_people_drawing_lots = 0;
        } else {
            item.quantity_for_event_prize = item.quantity * params.number_of_win_prize;
            params.number_of_people_drawing_lots = Math.round(params.number_of_win_prize * 1.2);
        }

        // estimated_remaining_quantity = warehousing_quantity - quantity_for_event_prize
        if (!item.warehousing_quantity || !item.quantity_for_event_prize) {
            item.estimated_remaining_quantity = 0;
            item.configEstimatedRemainingQuantity.hasWarning = false;
        } else {
            item.estimated_remaining_quantity = Number(item.warehousing_quantity) - Number(item.quantity_for_event_prize);
            if (item.estimated_remaining_quantity < 0) {
                item.configEstimatedRemainingQuantity.hasWarning = true;
            } else {
                item.configEstimatedRemainingQuantity.hasWarning = false;
            }
        }

        // remaining_quantity = warehousing_quantity - delivery_quantity
        if (!item.warehousing_quantity || !params.delivery_quantity) {
            item.remaining_quantity = 0;
            item.configRemainingQuantity.hasWarning = false;
        } else {
            item.remaining_quantity = Number(item.warehousing_quantity) - Number(params.delivery_quantity);
            if (item.remaining_quantity > 0) {
                item.configRemainingQuantity.hasWarning = true;
            } else {
                item.configRemainingQuantity.hasWarning = false;
            }
        }
    }
}

function handleAddNamePrize(arrayObjectPizes, idChild, idParent) {
    arrayObjectPizes[idParent].arrayNameAndQuantity.splice(idChild + 1, 0, {
        name: '',
        configPrizeName: {
            errorCustomMess: '',
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.namePrize')
        },
        quantity: 0,
        quantity_for_event_prize: 0,
        scheduled_arrived_at: '',
        warehousing_date: '',
        warehousing_quantity: 0,
        configWarehousingQuantity: {
            isNumber: true,
            maxlength: 10,
            error: false,
            errorCustomMess: '',
            classCustom: 'text-center'
        },
        estimated_remaining_quantity: 0,
        configEstimatedRemainingQuantity: {
            isNumber: true,
            maxlength: 10,
            error: false,
            errorCustomMess: '',
            hasWarning: false,
            classCustom: 'text-center'
        },
        memo: '',
        configMemo: {
            errorCustomMess: '',
            isRequired: false,
            isMaxLength: false,
            error: false,
            errorField: i18n.t('pageCampaign.tabPrize.memo')
        },
        detail_information: {
            delivery_company: '',
            configDeliveryCompany: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.shippingCompany')
            },
            tracking_number: '',
            configTrakingNumber: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.trackingNumber')
            },
            number_of_boxes_to_be_stocked: '',
            configNumberOfBoxesToBeStocked: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.expectedNumberOfBoxesInStock')
            },
            expiration_date: '',
            configExpirationDate: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.expirationDate')
            },
            storage_warehouse: null,
            configStorageWarehouse: {
                error: false,
                isRemove: false,
                loading: false,
                trackBy: 'id',
                label: 'value',
                openDirection: 'top'
            },
            locator_number: '',
            configLocatorNumber: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.locatorNumber')
            }
        },
        remaining_quantity: 0,
        configRemainingQuantity: {
            isNumber: true,
            maxlength: 10,
            error: false,
            errorCustomMess: '',
            hasWarning: false
        },
        remaining_response: {
            prize_less_status: null,
            configReturnPrizeStatus: {
                error: false,
                isRemove: false,
                loading: false,
                trackBy: 'id',
                label: 'value'
            },
            reconfirmation_date: null,
            reconfirmation_date_y: null,
            reconfirmation_date_m: null,
            reconfirmation_date_d: null,
            configReconfirmationDate: {
                error: false,
                errorText: ''
            },
            prize_refund_instructions: '',
            configPrizeRefundInstructions: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.prizeResponseInstructions')
            },
            preserve_fee: '',
            configPreserveFee: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.storageFee')
            },
            date_of_preserve: null,
            date_of_preserve_y: null,
            date_of_preserve_m: null,
            date_of_preserve_d: null,
            configDateOfPreserve: {
                error: false,
                errorText: ''
            },
            reference: '',
            configReference: {
                isRequired: false,
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pageCampaign.tabPrize.remarks')
            }
        }
    });
}

export {
    handleValidateArrayNameAndQuantity,
    handleMapDataPrizes,
    initObjectPize,
    handleExcuteMath,
    handleAddNamePrize,
    initObjectReturnPrizeItem,
    initObjectTypeTitle
};
