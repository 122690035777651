function handleDataScopePartner(arrPartner, type) {
    let arr = [];
    arrPartner.forEach((lv1) => {
        if (lv1.partner_corresponding) {
            arr.push({
                ...(lv1.idResgited && { id: lv1.idResgited }),
                master_order_scope_id: lv1.id,
                partner_id: type === 'register' ? lv1.partner_corresponding.id : lv1.partner_corresponding,
                tool_id: type === 'register' ? lv1.tool_corresponding?.id ?? null : lv1.tool_corresponding ?? null
            });
        }
        lv1.data.forEach((lv2) => {
            if (lv2.partner_corresponding) {
                arr.push({
                    ...(lv2.idResgited && { id: lv2.idResgited }),
                    master_order_scope_id: lv2.id,
                    partner_id: type === 'register' ? lv2.partner_corresponding.id : lv2.partner_corresponding,
                    tool_id: type === 'register' ? lv2.tool_corresponding?.id ?? null : lv2.tool_corresponding ?? null
                });
            }
            lv2.data.forEach((lv3) => {
                if (lv3.partner_corresponding) {
                    arr.push({
                        ...(lv3.idResgited && { id: lv3.idResgited }),
                        master_order_scope_id: lv3.id,
                        partner_id: type === 'register' ? lv3.partner_corresponding.id : lv3.partner_corresponding,
                        tool_id: type === 'register' ? lv3.tool_corresponding?.id ?? null : lv3.tool_corresponding ?? null
                    });
                }
                lv3.data?.forEach((lv4) => {
                    if (lv4.partner_corresponding) {
                        arr.push({
                            ...(lv4.idResgited && { id: lv4.idResgited }),
                            master_order_scope_id: lv4.id,
                            partner_id: type === 'register' ? lv4.partner_corresponding.id : lv4.partner_corresponding,
                            tool_id: type === 'register' ? lv4.tool_corresponding?.id ?? null : lv4.tool_corresponding ?? null
                        });
                    }
                });
            });
        });
    });
    return arr;
}

export { handleDataScopePartner };
