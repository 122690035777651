import moment from 'moment';
import i18n from '@/i18n';
import { LIST_GIVE_AWAY_RELATED } from '@/components/Campaign/Tab7/constants';
import { STR_DATE } from '@/utils/format';

function initObjectInformationIP(data = null) {
    let objInfo = {
        master_id: null,
        value: '',
        note: {
            detail: ''
        },
        checkedBox: [],
        children_data: []
    };
    if (!data) {
        objInfo = {
            ...objInfo
        };
    } else {
        objInfo = {
            ...objInfo,
            master_id: data?.id ?? null,
            value: data?.value ?? '',
            note: {
                detail: data?.note?.detail ?? ''
            },
            checkedBox: data?.children_data?.map((dataChil) => dataChil.id) ?? [],
            children_data: data?.children_data?.map((dataChil) => {
                return {
                    master_id: dataChil.id,
                    value: dataChil.value,
                    note: dataChil.note,
                    children_data: dataChil.children_data
                };
            })
        };
    }
    return objInfo;
}

function initObjectSponsor(data = null) {
    let objSponsor = {
        master_id: null,
        value: '',
        note: {
            manufacturer_name: '',
            distribution_name: '',
            circulation_number: '',
            circulation_distribution: '',
            up_manufacturers: '',
            up_manufacturers_name: '',
            configFactureName: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.manufacturerName') },
            configDistributeName: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.distributionName') },
            configCirculationNumber: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.circulationNumber') },
            configUpManufacturers: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.tie-upManufacturers') }
        },
        checkedBox: [],
        children_data: []
    };
    if (!data) {
        objSponsor = {
            ...objSponsor
        };
    } else {
        objSponsor = {
            ...objSponsor,
            master_id: data?.id ?? null,
            value: data?.value ?? '',
            note: {
                manufacturer_name: data?.note?.manufacturer_name ?? '',
                distribution_name: data?.note?.distribution_name ?? '',
                circulation_number: data?.note?.circulation_number ?? '',
                circulation_distribution: data?.note?.circulation_distribution ?? '',
                up_manufacturers: data?.note?.up_manufacturers ?? '',
                up_manufacturers_name: data?.note?.up_manufacturers_name ?? '',
                configFactureName: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.manufacturerName') },
                configDistributeName: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.distributionName') },
                configCirculationNumber: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.circulationNumber') },
                configUpManufacturers: { isMaxLength: false, error: false, errorField: i18n.t('pageCampaign.tie-upManufacturers') }
            },
            checkedBox: data?.children_data?.map((dataChil) => dataChil.id) ?? [],
            children_data: data?.children_data?.map((dataChil) => {
                return {
                    master_id: dataChil.id,
                    value: dataChil.value,
                    note: dataChil.note,
                    children_data: dataChil.children_data
                };
            })
        };
    }
    return objSponsor;
}

function initObjectTargetProduct(data = null, type = 'watch') {
    let objTargetProduct = {
        master_id: null,
        value: '',
        note: {
            detail: ''
        },
        children_data: []
    };
    if (!data) {
        objTargetProduct = {
            ...objTargetProduct
        };
    } else {
        const conditionData = data?.children_data ?? data?.data;
        objTargetProduct = {
            ...objTargetProduct,
            master_id: data?.id ?? null,
            value: data?.value ?? '',
            note: {
                detail: data?.note?.detail ?? ''
            },
            children_data:
                type == 'watch'
                    ? conditionData?.map((dataChil) => {
                          return {
                              master_id: dataChil.id,
                              value: dataChil.value,
                              note: {
                                  product_name: dataChil?.note?.product_name ?? ''
                              },
                              children_data: dataChil?.data ?? []
                          };
                      })
                    : []
        };
    }
    return objTargetProduct;
}

function initObjectClient(data = null) {
    let objClient = {
        id: null,
        client_id: null,
        configFieldSelectClient: {
            trackBy: 'id',
            label: 'name',
            errorField: 'エンドクライアント名',
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: ['requiredSelect']
        },
        client_pic_id: null,
        configFieldSelectClientPic: {
            trackBy: 'id',
            label: 'name',
            errorField: '担当	',
            valueSync: null,
            error: false,
            isRemove: false,
            isRequired: false,
            typesValidate: []
        }
    };
    if (!data) {
        objClient = {
            ...objClient
        };
    } else {
        objClient = {
            ...objClient,
            id: data?.id ?? null,
            client_id: data.client,
            configFieldSelectClient: {
                trackBy: 'id',
                label: 'name',
                errorField: 'エンドクライアント名',
                valueSync: null,
                error: false,
                isRemove: false,
                isRequired: false,
                typesValidate: ['requiredSelect']
            },
            client_pic_id: data.client_pic,
            configFieldSelectClientPic: {
                trackBy: 'id',
                label: 'name',
                errorField: '担当	',
                valueSync: null,
                error: false,
                isRemove: false,
                isRequired: false,
                typesValidate: []
            }
        };
    }
    return objClient;
}

function handleRecivedInformationIP(data, form) {
    form.information_ips = [];
    if (data.information_ips.length > 0) {
        data.information_ips.forEach((itemInforIP) => {
            form.information_ips.push(initObjectInformationIP(itemInforIP));
        });
    } else {
        form.information_ips.push(initObjectInformationIP(null));
    }
    return form.information_ips;
}

function handleRecivedSponsorInformation(data, form) {
    form.sponsor_informations = [];
    if (data.sponsor_informations.length > 0) {
        data.sponsor_informations.forEach((itemSponsor) => {
            form.sponsor_informations.push(initObjectSponsor(itemSponsor));
        });
    } else {
        form.sponsor_informations.push(initObjectSponsor(null));
    }
    return form.sponsor_informations;
}

function handleRecivedTargetProduct(data, form) {
    form.target_product_lists = [];
    form.listCheckedTargetLv2 = [];
    if (data.target_product_lists.length > 0) {
        form.listCheckedTargetLv1 = data.target_product_lists.map((dataParent) => dataParent.id) ?? [];
        data.target_product_lists.forEach((itemTargetProduct) => {
            form.listCheckedTargetLv2.push(...itemTargetProduct?.children_data?.map((dataChil) => dataChil.id));
            form.target_product_lists.push(initObjectTargetProduct(itemTargetProduct));
        });
    }
    return {
        target_product_lists: form.target_product_lists,
        listCheckedTargetLv1: form.listCheckedTargetLv1,
        listCheckedTargetLv2: form.listCheckedTargetLv2
    };
}

function handleDataDetail(
    data,
    campaignStatus,
    numberOfApplications,
    numberOfInquiries,
    sns_application_condition_1,
    application_condition_1,
    application_condition_2,
    isBookmarkCampaign,
    currentUser,
    listContactInformations,
    contact_informations,
    listDataMasterCategoryProductTarget,
    listDataMasterConditionPurchase1,
    purchase_conditions,
    saleStaffObject,
    personInChargeObject,
    listItemPersons,
    progressStatus,
    form,
    phonePic,
    customerTeamPic,
    configKeyword,
    hashTag,
    listCategories,
    listDataMasterCampaignTypeChild,
    listCategoriesSub,
    subDataCustomerTeamPic,
    listDataMasterCampaignType
) {
    if (!data) return;

    if (!data.sns_application_condition_1) {
        data.sns_application_condition_1 = {
            id: ''
        };
    } else {
        sns_application_condition_1 = data.sns_application_condition_1;
    }

    if (!data.application_condition_1) {
        data.application_condition_1 = {
            id: ''
        };
    } else {
        application_condition_1 = data.application_condition_1;
    }
    if (!data.application_condition_2) {
        data.application_condition_2 = {
            id: ''
        };
    } else {
        application_condition_2 = data.application_condition_2;
    }

    isBookmarkCampaign = currentUser.campaign_bookmarks?.some((item) => item.id === data.id) ?? false;

    let arrayDataContactInfor = [];
    if (data.contact_informations) {
        data.contact_informations.forEach((item) => {
            listContactInformations.forEach((listI) => {
                if (listI.child && listI.child.length > 0) {
                    let indexC = listI.child.findIndex((c) => {
                        return c.id == item.type?.id;
                    });
                    if (indexC >= 0) {
                        if (item.extension) {
                            contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                            arrayDataContactInfor.push(item.type.id);
                        }
                    }
                }
            });

            let index = listContactInformations.findIndex((x) => {
                return x.id == item?.type?.id;
            });

            if (index >= 0) {
                if (item.type.value === '電話') {
                    contact_informations[item.type.id] =
                        data?.phone_setting && data?.phone_setting?.disable_edit_field?.field_pre_order_phone_numbers
                            ? data.phone_setting.pre_order_phone_numbers ?? data.phone_setting.reservation
                            : '';
                } else {
                    if (item.extension) {
                        contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                    }
                }

                arrayDataContactInfor.push(item.type.id);
            }
        });
    }

    let arrayDataPurchase = [];
    if (data.purchase_conditions) {
        data.purchase_conditions.forEach((item) => {
            let index = listDataMasterConditionPurchase1.findIndex((x) => {
                return x.id == item.id;
            });
            if (index >= 0) {
                if (item.note) {
                    purchase_conditions[item.id] = item.note ? item.note.detail : '';
                }
                arrayDataPurchase.push(item.id);
            }
        });
    }

    let content_projects = [];
    if (data.content_projects) {
        data.content_projects.forEach((item) => {
            content_projects.push(item.id);
        });
    }

    let tool_useds = [];
    if (data.tool_useds) {
        data.tool_useds.forEach((item) => {
            tool_useds.push(item.id);
        });
    }

    let types = [];
    if (data.types) {
        data.types.forEach((item) => {
            types.push(item.id);
        });
    }

    let templates = [];
    if (data.templates) {
        data.templates.forEach((item) => {
            templates.push(item.id);
        });
    }

    if (data.part_time_employees.length == 0) {
        data.part_time_employees.push({
            id: '',
            name: ''
        });
    }
    saleStaffObject = data.sale_staff;
    personInChargeObject = data.person_in_charge;

    if (data.responsible_sub_persons.length) {
        if (data.responsible_sub_persons.length === 1) {
            if (data.responsible_sub_persons[0].sub_person_position === 1) {
                listItemPersons = [
                    ...data.responsible_sub_persons,
                    {
                        id: '',
                        name: '',
                        team: ''
                    }
                ];
            } else {
                listItemPersons = [
                    {
                        id: '',
                        name: '',
                        team: ''
                    },
                    ...data.responsible_sub_persons
                ];
            }
        } else if (data.responsible_sub_persons.length === 2) {
            listItemPersons = data.responsible_sub_persons;
        }
    }

    let idex = campaignStatus.findIndex((x) => x.id == data.progress_status);
    if (idex >= 0) {
        progressStatus = campaignStatus[idex];
    }
    data.configCampaignStartDateTime = form.configCampaignStartDateTime;
    data.configCampaignEndDateTime = form.configCampaignEndDateTime;
    data.configSecretariatStartDateTime = form.configSecretariatStartDateTime;
    data.configSecretariatEndDateTime = form.configSecretariatEndDateTime;
    data.configUrlPublishTimeFrom = form.configUrlPublishTimeFrom;
    data.configUrlPublishTimeTo = form.configUrlPublishTimeTo;
    data.configReceiptExpirationDate = form.configReceiptExpirationDate;
    let resultTargetProduct = handleRecivedTargetProduct(data, form);
    form = {
        ...data,
        give_away: data?.give_away ?? '0',
        source_data: data?.source_data ?? {
            type: '',
            id: ''
        },
        number_of_applications: data?.number_of_applications ?? [],
        total_number_of_applications: data?.total_number_of_applications ?? 0,
        number_of_inquiries: data?.number_of_inquiries ?? [],
        total_number_of_inquiries: data?.total_number_of_inquiries ?? 0,
        platform_followers_number: data?.platform_followers_number ?? [],
        advertising_activity: data?.advertising_activity ?? null,
        social_platforms: data?.social_platforms ?? [],
        give_away_related: data?.give_away_related !== null ? LIST_GIVE_AWAY_RELATED[data?.give_away_related] : null,
        account_infor_on_screen: data?.account_infor_on_screen ?? null,
        pdca_file_report: data?.pdca_file_report ?? null,
        cashback_file_report: data?.cashback_file_report ?? null,

        campaign_type: {
            id: data?.campaign_type?.id ?? null,
            key: data?.campaign_type?.key ?? '',
            value: data?.campaign_type?.value ?? ''
        },
        sns_application_condition_1: data?.sns_application_condition_1 ?? {
            id: ''
        },
        application_condition_1: data?.application_condition_1 ?? {
            id: ''
        },
        application_condition_2: data?.application_condition_2 ?? {
            id: ''
        },
        team: data?.team ?? {
            id: ''
        },
        sale_staff: data?.sale_staff ?? {
            id: ''
        },
        information_ips: handleRecivedInformationIP(data, form),
        sponsor_informations: handleRecivedSponsorInformation(data, form),
        target_product_lists: resultTargetProduct.target_product_lists,
        listCheckedTargetLv1: resultTargetProduct.listCheckedTargetLv1,
        listCheckedTargetLv2: resultTargetProduct.listCheckedTargetLv2,
        clients: data?.clients?.map((clientItem) => initObjectClient(clientItem))
    };

    form.chatwork_rooms =
        data.chatwork_rooms.length > 0
            ? data.chatwork_rooms.map((item) => {
                  return {
                      room_id: item.room_id ?? '',
                      room_name: item.room_name ?? ''
                  };
              })
            : [
                  {
                      room_id: '',
                      room_name: ''
                  }
              ];
    form.chat_rooms = data.chat_rooms;

    handleMapDate(data, form);
    form.contact_informations = arrayDataContactInfor;
    form.purchase_conditions = arrayDataPurchase;
    form.content_projects = content_projects;
    form.tool_useds = tool_useds;
    form.types = types;
    form.templates = templates;
    form.receipt_expiration_date = data.receipt_expiration_date ?? '';
    form.give_away_information = data.give_away_information
        ? data.give_away_information
        : {
              inflow_destination: '',
              inflow_rate: '',
              attrition_rate: ''
          };
    phonePic = data.phone_pic ?? '';
    customerTeamPic = data.customer_team_pic ?? '';
    configKeyword.arrayTag = data.keywords;
    hashTag.arrayTag = data.hashtag;

    listDataMasterCampaignTypeChild = getDataCampaignTye(listDataMasterCampaignType, form, listDataMasterCampaignTypeChild);
    listCategories = [];
    listCategoriesSub = [];
    form.categories.forEach((item) => {
        listCategories.push(item.category_id);
        let index = listDataMasterCampaignTypeChild.findIndex((x) => {
            return x.id == item.category_id;
        });
        let check = listDataMasterCampaignTypeChild.findIndex((x) => {
            return x.id == item.category_id && (x.value == 'Twitter' || x.value == 'Instagram' || x.value == 'LINE' || x.value == 'Tiktok');
        });
        if (index >= 0) {
            let obj = {
                category_id: item.category_id,
                category_value: listDataMasterCampaignTypeChild.find((typeName) => typeName.id == item.category_id)?.value,
                application_condition_1: item.application_condition_1,
                application_condition_2_id: item.application_condition_2_id,
                listCondition1: listDataMasterCampaignTypeChild[index].data,
                listCondition2: [],
                hashtag: item.hashtag ?? [],
                configHashTag: {
                    arrayTag: item.hashtag ?? []
                },
                extension: item.extension
                    ? item.extension
                    : {
                          offical_name_sns: '',
                          offical_id_sns: '',
                          number_of_circulation: '',
                          company_information: '',
                          other_type_data: '',
                          application_method: '',
                          content_other: ''
                      },
                checkSNS: check
            };

            let i = obj.listCondition1.findIndex((x) => {
                return obj.application_condition_1 && x.id == obj.application_condition_1.id;
            });
            if (i >= 0) {
                obj.listCondition2 = obj.listCondition1[i].data;
            }
            listCategoriesSub.push(obj);
            listCategoriesSub = listCategoriesSub.sort((a, b) => a.category_id - b.category_id);
        }
    });
    subDataCustomerTeamPic = data.customer_sub_person.length > 0 ? data.customer_sub_person : [null];

    return {
        campaignStatus,
        numberOfApplications,
        numberOfInquiries,
        sns_application_condition_1,
        application_condition_1,
        application_condition_2,
        isBookmarkCampaign,
        currentUser,
        listContactInformations,
        contact_informations,
        listDataMasterCategoryProductTarget,
        listDataMasterConditionPurchase1,
        purchase_conditions,
        saleStaffObject,
        personInChargeObject,
        listItemPersons,
        progressStatus,
        form,
        phonePic,
        customerTeamPic,
        configKeyword,
        hashTag,
        listCategories,
        listCategoriesSub,
        subDataCustomerTeamPic,
        listDataMasterCampaignTypeChild
    };
}

function getDataCampaignTye(listDataMasterCampaignType, form, listDataMasterCampaignTypeChild) {
    let index = listDataMasterCampaignType.findIndex((x) => {
        return x.id == form.campaign_type.id;
    });

    if (index >= 0) {
        listDataMasterCampaignTypeChild = listDataMasterCampaignType[index].data;
    }
    return listDataMasterCampaignTypeChild;
}

function handleMapDate(argData, form) {
    form.campaign_start_time = argData.campaign_start_date ? moment(argData.campaign_start_date).format('HH:mm') : '';
    form.campaign_end_time = argData.campaign_end_date ? moment(argData.campaign_end_date).format('HH:mm') : '';
    form.secretariat_start_time = argData.secretariat_start_date ? moment(argData.secretariat_start_date).format('HH:mm') : '';
    form.secretariat_end_time = argData.secretariat_end_date ? moment(argData.secretariat_end_date).format('HH:mm') : '';
    form.url_publish_time_from_time = argData.url_publish_time_from ? moment(argData.url_publish_time_from).format('HH:mm') : '';
    form.url_publish_time_to_time = argData.url_publish_time_to ? moment(argData.url_publish_time_to).format('HH:mm') : '';

    form.campaign_start_date = argData.campaign_start_date ? moment(argData.campaign_start_date).format(STR_DATE) : '';
    form.campaign_end_date = argData.campaign_end_date ? moment(argData.campaign_end_date).format(STR_DATE) : '';
    form.secretariat_start_date = argData.secretariat_start_date ? moment(argData.secretariat_start_date).format(STR_DATE) : '';
    form.secretariat_end_date = argData.secretariat_end_date ? moment(argData.secretariat_end_date).format(STR_DATE) : '';
    form.url_publish_time_from = argData.url_publish_time_from ? moment(argData.url_publish_time_from).format(STR_DATE) : '';
    form.url_publish_time_to = argData.url_publish_time_to ? moment(argData.url_publish_time_to).format(STR_DATE) : '';
    return form;
}

function handleMapDataSever(tableScopeOrder, campaign_master_order_scopes, has_tel_phone_setting) {
    let dataAfterMap = [];
    tableScopeOrder.forEach((itemTableScope, indexTableScope) => {
        if (campaign_master_order_scopes.length <= 0) {
            dataAfterMap.push({
                large_item: itemTableScope.id,
                medium_items: itemTableScope.data.map((itemLv2) => {
                    return {
                        medium_item: itemLv2.id,
                        small_items: itemLv2.data.map((itemLv3) => {
                            return {
                                small_item: itemLv3.id,
                                detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                            };
                        })
                    };
                })
            });
        } else {
            if (campaign_master_order_scopes[indexTableScope]?.id) {
                if (itemTableScope.id === campaign_master_order_scopes[indexTableScope].large_item?.id) {
                    dataAfterMap.push({
                        large_item: itemTableScope.id,
                        medium_items: itemTableScope.data.map((itemLv2) => {
                            return {
                                medium_item: itemLv2.id,
                                small_items: itemLv2.data.map((itemLv3) => {
                                    return {
                                        small_item: itemLv3.id,
                                        detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                                    };
                                })
                            };
                        })
                    });
                } else {
                    dataAfterMap.push({
                        large_item: itemTableScope.id,
                        medium_items: itemTableScope.data.map((itemLv2) => {
                            return {
                                medium_item: itemLv2.id,
                                small_items: itemLv2.data.map((itemLv3) => {
                                    return {
                                        small_item: itemLv3.id,
                                        detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                                    };
                                })
                            };
                        })
                    });
                }
            } else {
                dataAfterMap.push({
                    large_item: itemTableScope.id,
                    medium_items: itemTableScope.data.map((itemLv2) => {
                        return {
                            medium_item: itemLv2.id,
                            small_items: itemLv2.data.map((itemLv3) => {
                                return {
                                    small_item: itemLv3.id,
                                    detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                                };
                            })
                        };
                    })
                });
            }
        }
    });
    return {
        campaign_master_order_scopes: dataAfterMap,
        has_tel_phone_setting: handleCheckConditionTel(dataAfterMap, has_tel_phone_setting)
    };
}

function handleCheckConditionTel(campaign_master_order_scopes, has_tel_phone_setting) {
    has_tel_phone_setting = false;
    if (campaign_master_order_scopes && campaign_master_order_scopes.length > 0) {
        let getDetails = [];
        campaign_master_order_scopes.forEach((largeItem) => {
            largeItem?.medium_items?.forEach((mediumItem) => {
                getDetails.push(`${mediumItem.medium_item}`);
            });
        });
        // 2 là id của điều kiện "電話" (TEL)
        has_tel_phone_setting = getDetails.includes('2');
    }
    return has_tel_phone_setting;
}

function handleDataIPSend(dataIP) {
    dataIP = dataIP.map((itemIP) => {
        return {
            master_id: itemIP.master_id,
            note: {
                detail: itemIP.note.detail
            },
            children_data: itemIP.children_data.map((childLv1) => {
                return {
                    master_id: childLv1.master_id,
                    note: childLv1.note,
                    children_data: childLv1.children_data
                };
            })
        };
    });
    return dataIP;
}

function handleDataSponsorSend(dataSponsor) {
    dataSponsor = dataSponsor.map((itemSS) => {
        return {
            master_id: itemSS.master_id,
            note: {
                manufacturer_name: itemSS?.note?.manufacturer_name ?? '',
                distribution_name: itemSS?.note?.distribution_name ?? '',
                circulation_number: itemSS?.note?.circulation_number ?? '',
                circulation_distribution: itemSS?.note?.circulation_distribution ?? '',
                up_manufacturers: itemSS?.note?.up_manufacturers ?? '',
                up_manufacturers_name: itemSS?.note?.up_manufacturers_name ?? ''
            },
            children_data: itemSS.children_data.map((childLv1) => {
                return {
                    master_id: childLv1.master_id,
                    note: childLv1.note,
                    children_data: childLv1.children_data
                };
            })
        };
    });
    return dataSponsor;
}

function handleDataTargetProductSend(dataTargetProduct) {
    dataTargetProduct = dataTargetProduct.map((itemTP) => {
        return {
            master_id: itemTP?.master_id ?? itemTP.id,
            note: {
                detail: itemTP?.note?.detail ?? ''
            },
            children_data: itemTP?.children_data.map((childLv1) => {
                return {
                    master_id: childLv1?.master_id ?? childLv1.id,
                    note: childLv1.note,
                    children_data: childLv1.children_data
                };
            })
        };
    });
    return dataTargetProduct;
}

function handleDataNumberOfApplicationsSend(numberOfApplications) {
    numberOfApplications = numberOfApplications.map((itemNumberA) => {
        return {
            master: {
                id: itemNumberA?.master?.id ?? null
            },
            quantity: Number(itemNumberA?.quantity) ?? 0
        };
    });
    return numberOfApplications;
}

function handleDataNumberOfInquiriesSend(numberOfInquiries) {
    numberOfInquiries = numberOfInquiries.map((itemNumberB) => {
        return {
            master_order_scope: {
                id: itemNumberB?.master_order_scope?.id ?? null
            },
            quantity: Number(itemNumberB?.quantity) ?? 0
        };
    });
    return numberOfInquiries;
}

function handleDataPlatformFollowersNumberSend(platformFollowersNumber) {
    platformFollowersNumber = platformFollowersNumber.map((itemNumberC) => {
        return {
            master: {
                id: itemNumberC?.master?.id ?? null
            },
            initial_quantity: Number(itemNumberC?.initial_quantity) ?? 0,
            final_quantity: Number(itemNumberC?.final_quantity) ?? 0,
            increase_quantity: Number(itemNumberC?.increase_quantity) ?? 0
        };
    });
    return platformFollowersNumber;
}

function handleClearDateBeforeSend(dataOrigin) {
    const formCampaignCopy = { ...dataOrigin };
    if (!formCampaignCopy.isShowPublicTime) {
        formCampaignCopy.url_publish_time_from = '';
        formCampaignCopy.url_publish_time_to = '';
    }

    if (!formCampaignCopy.isShowReceiptExpirationDate) {
        delete formCampaignCopy.receipt_expiration_date;
    }
    // Xóa các thuộc tính không cần thiết
    delete formCampaignCopy.campaign_start_time;
    delete formCampaignCopy.campaign_end_time;
    delete formCampaignCopy.secretariat_start_time;
    delete formCampaignCopy.secretariat_end_time;
    delete formCampaignCopy.url_publish_time_from_time;
    delete formCampaignCopy.url_publish_time_to_time;

    delete formCampaignCopy.configCampaignStartDateTime;
    delete formCampaignCopy.configCampaignEndDateTime;
    delete formCampaignCopy.configSecretariatStartDateTime;
    delete formCampaignCopy.configSecretariatEndDateTime;
    delete formCampaignCopy.configUrlPublishTimeFrom;
    delete formCampaignCopy.configUrlPublishTimeTo;
    delete formCampaignCopy.configReceiptExpirationDate;
    delete formCampaignCopy.isShowPublicTime;
    delete formCampaignCopy.isShowReceiptExpirationDate;
    delete formCampaignCopy.receipt_expiration_date_d;
    delete formCampaignCopy.receipt_expiration_date_m;
    delete formCampaignCopy.receipt_expiration_date_y;

    return formCampaignCopy;
}

function handleDataSocialPlatformsSend(socialPlatforms) {
    socialPlatforms = socialPlatforms.map((itemNumberD) => {
        return {
            master: {
                id: itemNumberD?.master?.id ?? null
            },
            note: {
                detail: itemNumberD?.note?.detail ?? ''
            }
        };
    });
    return socialPlatforms;
}

export {
    handleRecivedSponsorInformation,
    handleRecivedInformationIP,
    handleDataDetail,
    handleMapDataSever,
    handleMapDate,
    initObjectTargetProduct,
    handleDataSponsorSend,
    handleDataTargetProductSend,
    handleDataIPSend,
    handleDataNumberOfApplicationsSend,
    handleDataNumberOfInquiriesSend,
    handleDataPlatformFollowersNumberSend,
    handleClearDateBeforeSend,
    handleCheckConditionTel,
    handleDataSocialPlatformsSend,
    initObjectClient
};
