import i18n from '@/i18n';
import moment from 'moment';
import { mapState, mapGetters, mapActions } from 'vuex';
import { handleMapDataPrizes } from '@/components/Campaign/TabPrize/index.js';
import {
    handleMapDataSever,
    handleDataSponsorSend,
    handleDataTargetProductSend,
    handleDataIPSend,
    handleDataNumberOfApplicationsSend,
    handleDataNumberOfInquiriesSend,
    handleDataPlatformFollowersNumberSend,
    handleClearDateBeforeSend,
    handleCheckConditionTel,
    handleDataSocialPlatformsSend
} from '@/views/pages/campaign/edit/index';
import { handleDataScopePartner } from '@/components/Campaign/TabTool/index.js';

export const authComputed = {
    ...mapState('authfack', {
        currentUser: (state) => state.user
    }),
    ...mapGetters('authfack', ['loggedIn'])
};

export const masterComputed = {
    ...mapState('master', {
        dataList: (state) => state.dataList
    })
};

export const layoutComputed = {
    ...mapState('layout', {
        layoutType: (state) => state.layoutType,
        leftSidebarType: (state) => state.leftSidebarType,
        layoutWidth: (state) => state.layoutWidth,
        topbar: (state) => state.topbar,
        loader: (state) => state.loader
    })
};

export const authMethods = mapActions('auth', ['logIn', 'logOut', 'register', 'resetPassword']);

export const layoutMethods = mapActions('layout', [
    'changeLayoutType',
    'changeLayoutWidth',
    'changeLeftSidebarType',
    'changeTopbar',
    'changeLoaderValue'
]);

export const authFackMethods = mapActions('authfack', ['login', 'registeruser', 'logout', 'profile', 'forgot', 'resetPass']);

export const masterMethods = mapActions('master', [
    'setDefaultCampaign',
    'postDocumentFAQPreview',
    'postDocumentFAQ',
    'deleteDocumentFAQ',
    'updateDocumentFAQ',
    'getDocumentFAQ',
    'getDefaultRule',
    'createMasterRuleDetail1',
    'getDetailDocuments',
    'getAllDocuments',
    'updateMasterRuleDetail1',
    'detailMasterRule1',
    'getAllMasterRule',
    'deleteMasterRule2',
    'createMasterRule1',
    'deleteMasterRule1',
    'updateMasterRule1',
    'updateMasterRule',
    'listMasterRule1',
    'registerMasterRule',
    'updateRule2',
    'deleteRule2',
    'createRule2',
    'listRule',
    'documentPreview',
    'templateDelete',
    'templateCreate',
    'templateUpdate',
    'removeDraf',
    'saveDraf',
    'templateGetDraf',
    'templateGetDetail',
    'templateList',
    'deleteMasterHolidayYear',
    'updateHolidayYear',
    'listHolidayYear',
    'oneHolidayYear',
    'registerHolidayYear',
    'deleteMasterHoliday',
    'updateHoliday',
    'listHoliday',
    'registerHoliday',
    'histories',
    'findCompany',
    'searchAddress',
    'registerPermission',
    'dataPermission',
    'listPermission',
    'listMaster',
    'registerMaster',
    'deleteMaster',
    'updateMaster',
    'masterCampaigns',
    'getListScheduleFile',
    'getDetailScheduleFile',
    'createScheduleFile',
    'updateScheduleFile',
    'deleteScheduleFile',
    'partnerRequestSheet',
    'getPartnerRequestSheet',
    'updatePartnerRequestSheet',
    'getMasterOrderScope'
]);

export const employeeMethods = mapActions('employee', [
    'listEmployeePartTime',
    'registerEmployeePartTime',
    'registerEmployee',
    'detailEmployeePartTime',
    'updateEmployeePartTime',
    'updateEmployee',
    'listEmployee',
    'deleteEmployee',
    'detailEmployee',
    'deleteEmployeePartTime'
]);

export const partnersMethods = mapActions('partners', [
    'registerPartners',
    'updatePartners',
    'listPartners',
    'deletePartners',
    'detailPartners',
    'listPartnersSupport',
    'registerPartnersSupport',
    'updatePartnersSupport',
    'detailPartnersSupport',
    'deletePartnersSupport',
    'getPartnersSettingPhone'
]);

export const clientMethods = mapActions('client', [
    'registerClient',
    'updateClient',
    'listClient',
    'deleteClient',
    'detailClientSupport',
    'listClientSupport',
    'registerClientSupport',
    'updateClientSupport',
    'deleteClientSupport',
    'detailClient'
]);

export const campaignMethods = mapActions('campaign', [
    'historyDocuments',
    'postDocuments',
    'copyCampaign',
    'uploadFile',
    'getDocuments',
    'triggerDocuments',
    'deleteBookmark',
    'getBookmark',
    'addBookmark',
    'approveCampaign',
    'unLockCampaign',
    'lockCampaign',
    'registerCampaign',
    'updateCampaign',
    'listCampaign',
    'deleteCampaign',
    'detailCampaign',
    'getDataDocuments',
    'getRuleSavePayLoad',
    'postRuleSavePayLoad',
    'setBookmarkCampaign',
    'unsetBookmarkCampaign'
]);

export const faqMethods = mapActions('faq', [
    'registerFaq',
    'updateFaqs',
    'updateFaqsAnswerDefault',
    'listFaqs',
    'deleteFaq',
    'detailFaq',
    'listPartnersSupport',
    'registerAnswer',
    'updateAnswer',
    'detailPartnersSupport',
    'deleteAnswer'
]);

export const prizeMethods = mapActions('prize', [
    'deletePrizeReturn',
    'listPrizeReturn',
    'registerPrizeReturn',
    'updatePrizeReturn',
    'registerPrize',
    'updatePrize',
    'updateFaqsAnswerDefault',
    'listPrize',
    'deletePrize',
    'detailPrize',
    'registerAnswer',
    'updateAnswer',
    'detailPartnersSupport',
    'deleteAnswer',
    'getPrizesAll'
]);

export const notificationMethods = mapActions('notification', ['success', 'error', 'clear']);

export const dashboardMethods = mapActions('dashboard', ['listCountEmployeeDashboard']);

export const settingPhoneMethods = mapActions('settingPhone', [
    'detailPhone',
    'registerPhone',
    'updatePhone',
    'updateStepWorkflow',
    'getListUserInRoom',
    'getInfoChatWork',
    'getTemplateConfig'
]);

export const manualService = mapActions('manual', ['detailManual', 'updateManual']);

export const listPaging = [10, 30, 50];

export const dataMaster = [
    {
        name: 'パルディア社員',
        key: 'employee',
        rowspan: 1,
        childMain: '権限・チーム関連',
        keyMain: 'department',
        child: []
    },
    {
        name: 'FAQ',
        key: 'faq_master',
        rowspan: 1,
        childMain: 'FAQシート項目',
        keyMain: 'sheets',
        child: []
    },
    {
        name: 'キャンペーン',
        key: 'campaign',
        rowspan: 36,
        childMain: '',
        keyMain: 'status',
        child: [
            {
                name: i18n.t('pageCampaign.targetProductCategory'),
                key: 'product_target'
            },
            {
                name: '当選数',
                key: 'number_prizes'
            },
            {
                name: 'SNS応募条件',
                key: 'conditions_applying_sns'
            },
            {
                name: 'タレント・キャラクター',
                key: 'talent_character'
            },
            {
                name: '告知方法',
                key: 'announcement_method'
            },
            {
                name: 'エンドクライエントカテゴリ',
                key: 'category_customer'
            },
            {
                name: 'メーカー',
                key: 'maker'
            },
            {
                name: '流通',
                key: 'distribution'
            },
            {
                name: '食品',
                key: 'food'
            },
            {
                name: '食料',
                key: 'drink'
            },
            {
                name: '医薬品',
                key: 'pharmaceuticals'
            },
            {
                name: '化粧品',
                key: 'cosmetics'
            },
            {
                name: 'トイレタリー・日用品',
                key: 'toiletries_daily_necessities'
            },
            {
                name: '家電製品',
                key: 'home_appliances'
            },
            {
                name: 'AV・コンピューター及び周辺機器',
                key: 'AVComputers_and_peripherals'
            },
            {
                name: '通信サービス',
                key: 'communication_service'
            },
            {
                name: '衣料･ファッション･装飾品',
                key: 'clothing_fashion_and_accessories'
            },
            {
                name: 'おもちゃ',
                key: 'toy'
            },
            {
                name: '出版･趣味･娯楽品',
                key: 'publishing_hobbies_entertainment_items'
            },
            {
                name: '家庭用品',
                key: 'household_goods'
            },
            {
                name: 'ペット用品',
                key: 'pet_supplies'
            },
            {
                name: '案件内容',
                key: 'project_details'
            },
            {
                name: 'ツール',
                key: 'tool'
            },
            {
                name: '購買条件',
                key: 'purchase_conditions_1'
            },
            {
                name: '対応指示',
                key: 'prize_less_status'
            },
            {
                name: 'お問い合わせ',
                key: 'inquiry'
            },
            {
                name: '種別',
                key: 'type'
            },
            {
                name: '賞品カテゴリ',
                key: 'gift_category'
            },
            {
                name: '賞品あまりステータス',
                key: 'prize.remain_status'
            },
            {
                name: 'デジタルギフト',
                key: 'prize.digital_gift'
            }
        ]
    }
];

export const keyMaster = {
    'employee.team': 'employee.team',
    'employee.department': 'employee.department',
    'foreign_partner.type': 'foreign_partner.type',
    'faq_master.category': 'faq_master.category',
    'faq_master.sheets': 'faq_master.sheets',
    'prize.prize_less_status': 'prize.prize_less_status',
    'campaign.type': 'campaign.type',
    'campaign.status': 'campaign.status',
    'campaign.conditions_applying_admission': 'campaign.conditions_applying_admission',
    'campaign.project_details': 'campaign.project_details',
    'campaign.conditions_applying_sns': 'campaign.conditions_applying_sns',
    'campaign.product_target': 'campaign.product_target',
    'campaign.purchase_condition': 'campaign.purchase_condition',
    'campaign.purchase_conditions_2': 'campaign.purchase_conditions_2',
    'campaign.tool': 'campaign.tool',
    'campaign.information_ip': 'campaign.information_ip',
    'prize.corresponding_status': 'prize.corresponding_status',
    'prize.storage_warehouse': 'prize.storage_warehouse',
    'campaign.sponsor_information': 'campaign.sponsor_information',
    'campaign.advertising': 'campaign.advertising',
    'campaign.social_platform': 'campaign.social_platform',
    'client.agency_type': 'client.agency_type',
    'client.maker_type': 'client.maker_type',
    'client.circulate_type': 'client.circulate_type'
};

export const nameMaster = {
    'employee.team': 'チームマスタ',
    'employee.group': 'グループマスタ'
};
//管理者 quan ly  administrator
//営業 kinh doanh sale
//ディレクション huong dan instructor
//parter
//part_time
export function checkPermission(role) {
    const user = JSON.parse(localStorage.getItem('user'));
    let roleUser = user.role;
    let permission = roleUser.permissions;
    let index = permission.findIndex((x) => {
        return x.name === role;
    });
    if (index >= 0) {
        return true;
    } else {
        return false;
    }
}

export function checkPermissionName(listRole) {
    const user = JSON.parse(localStorage.getItem('user'));
    let role = user.role ? user.role.name : '';
    if (listRole.includes(role)) {
        return true;
    } else {
        return false;
    }
}

export function formattedPostalCode(data) {
    if (!data) return '';
    return data.replace(/(\d{3})(\d{4})/, '$1-$2');
}

export function isValidDate(dateString) {
    // Kiểm tra định dạng ngày tháng năm (DD/MM/YYYY)
    const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

    if (!dateRegex.test(dateString)) {
        // Nếu không đúng định dạng, trả về false
        return false;
    }

    // Chuyển đổi chuỗi thành đối tượng Date
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Tạo đối tượng Date và kiểm tra ngày có tồn tại không
    const dateObject = new Date(year, month - 1, day); // month - 1 vì tháng trong JavaScript bắt đầu từ 0

    // Kiểm tra xem ngày có tồn tại trong tháng không
    if (dateObject.getFullYear() === year && dateObject.getMonth() === month - 1 && dateObject.getDate() === day) {
        // Kiểm tra xem ngày có hợp lệ trong tháng không
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        return day >= 1 && day <= lastDayOfMonth;
    }

    return false;
}

export function mapDataCampaigns(
    formData,
    configKeyword,
    listItemPersons,
    purchase_conditions_data,
    contact_informations_data,
    listCategoriesSub,
    subDataCustomerTeamPic,
    arrayObjectPizes,
    tableScopeOrder,
    listnumberOfInquiries
) {
    let formatDateSend = 'YYYY-MM-DD HH:mm';
    let objectData = JSON.parse(JSON.stringify(formData));
    objectData.campaign_type = objectData.campaign_type?.id ?? null;
    if (objectData.associated_campaign) {
        objectData.associated_campaign = {
            id: objectData.associated_campaign?.id ?? null
        };
    }
    objectData.keywords = configKeyword.arrayTag;
    let purchase_conditions = [];
    objectData.purchase_conditions.forEach((item) => {
        purchase_conditions.push({
            master_id: item,
            note: {
                detail: purchase_conditions_data[item]
            }
        });
    });
    let contact_informations = [];
    // item == 3 không gửi phone number khi đang đang lấy từ phone setting
    objectData.contact_informations.forEach((item) => {
        contact_informations.push({
            type_id: item,
            extension: {
                content: item !== 3 ? (contact_informations_data ? contact_informations_data[item] : '') : ''
            }
        });
    });
    let part_time_employees = [];
    objectData.part_time_employees.forEach((item) => {
        if (item.id) part_time_employees.push(item.id);
    });
    let responsible_sub_persons = [];
    listItemPersons.forEach((x, idx) => {
        if (x.id) {
            responsible_sub_persons.push({
                user_id: x.id,
                sub_person_position: idx + 1
            });
        }
    });
    objectData.purchase_conditions = purchase_conditions;
    objectData.contact_informations = contact_informations;
    objectData.responsible_sub_persons = responsible_sub_persons;

    objectData.clients = objectData.clients.map((cl) => {
        return {
            ...(cl.id !== null &&
                cl.id !== undefined && {
                    id: cl.id
                }),
            client_id: cl?.client_id?.id ?? null,
            client_pic_id: cl?.client_pic_id?.id ?? null
        };
    });

    objectData.prizes = [];
    objectData.part_time_employees = part_time_employees;
    objectData.categories = JSON.parse(JSON.stringify(listCategoriesSub));
    objectData.categories.forEach((item) => {
        item.hashtag = item?.configHashTag?.arrayTag ?? [];
        delete item.listCondition1;
        delete item.listCondition2;
    });
    delete objectData.updated_by;
    if (objectData.campaign_start_date_y && objectData.campaign_start_date_m && objectData.campaign_start_date_d) {
        objectData.campaign_start_date =
            objectData.campaign_start_date_y + '-' + objectData.campaign_start_date_m + '-' + objectData.campaign_start_date_d;
    }
    if (objectData.campaign_end_date_y && objectData.campaign_end_date_m && objectData.campaign_end_date_d) {
        objectData.campaign_end_date = objectData.campaign_end_date_y + '-' + objectData.campaign_end_date_m + '-' + objectData.campaign_end_date_d;
    }
    if (objectData.secretariat_start_date_y && objectData.secretariat_start_date_m && objectData.secretariat_start_date_d) {
        objectData.secretariat_start_date =
            objectData.secretariat_start_date_y + '-' + objectData.secretariat_start_date_m + '-' + objectData.secretariat_start_date_d;
    }
    if (objectData.secretariat_end_date_y && objectData.secretariat_end_date_m && objectData.secretariat_end_date_d) {
        objectData.secretariat_end_date =
            objectData.secretariat_end_date_y + '-' + objectData.secretariat_end_date_m + '-' + objectData.secretariat_end_date_d;
    }
    if (objectData.receipt_expiration_date_y && objectData.receipt_expiration_date_m && objectData.receipt_expiration_date_d) {
        objectData.receipt_expiration_date =
            objectData.receipt_expiration_date_y + '-' + objectData.receipt_expiration_date_m + '-' + objectData.receipt_expiration_date_d;
    }

    if (objectData?.client_order_source?.id) {
        objectData.client_order_source = {
            id: objectData?.client_order_source?.id
        };
    }

    objectData.customer_sub_person = subDataCustomerTeamPic[0] !== null ? subDataCustomerTeamPic.map((item) => item.id) : [];
    objectData.information_ips = objectData.information_ips[0]?.master_id == null ? [] : handleDataIPSend(objectData.information_ips);
    objectData.sponsor_informations =
        objectData.sponsor_informations[0]?.master_id == null ? [] : handleDataSponsorSend(objectData.sponsor_informations);

    // campaign_start_date
    objectData.campaign_start_date = objectData.campaign_start_time
        ? moment(objectData.campaign_start_date + ' ' + objectData.campaign_start_time, formatDateSend).format(formatDateSend)
        : objectData.campaign_start_date ?? '';

    // campaign_end_date
    objectData.campaign_end_date = objectData.campaign_end_time
        ? moment(objectData.campaign_end_date + ' ' + objectData.campaign_end_time, formatDateSend).format(formatDateSend)
        : objectData.campaign_end_date ?? '';

    // secretariat_start_date
    objectData.secretariat_start_date = objectData.secretariat_start_time
        ? moment(objectData.secretariat_start_date + ' ' + objectData.secretariat_start_time, formatDateSend).format(formatDateSend)
        : objectData.secretariat_start_date ?? '';

    // secretariat_end_date
    objectData.secretariat_end_date = objectData.secretariat_end_time
        ? moment(objectData.secretariat_end_date + ' ' + objectData.secretariat_end_time, formatDateSend).format(formatDateSend)
        : objectData.secretariat_end_date ?? '';

    // url_publish_time_from
    objectData.url_publish_time_from = objectData.url_publish_time_from_time
        ? moment(objectData.url_publish_time_from + ' ' + objectData.url_publish_time_from_time, formatDateSend).format(formatDateSend)
        : objectData.url_publish_time_from ?? '';

    // url_publish_time_to
    objectData.url_publish_time_to = objectData.url_publish_time_to_time
        ? moment(objectData.url_publish_time_to + ' ' + objectData.url_publish_time_to_time, formatDateSend).format(formatDateSend)
        : objectData.url_publish_time_to ?? '';

    objectData.event_prizes = handleMapDataPrizes(arrayObjectPizes);
    objectData.campaign_master_order_scope_partner = handleDataScopePartner(tableScopeOrder, 'register');

    const resultMapDataServe = handleMapDataSever(tableScopeOrder, objectData.campaign_master_order_scopes, objectData.has_tel_phone_setting);
    objectData.campaign_master_order_scopes = resultMapDataServe.campaign_master_order_scopes;
    objectData.has_tel_phone_setting = resultMapDataServe.has_tel_phone_setting;
    objectData.target_product_lists = handleDataTargetProductSend(objectData.target_product_lists);
    objectData.number_of_applications = handleDataNumberOfApplicationsSend(objectData.number_of_applications);
    objectData.number_of_inquiries = handleDataNumberOfInquiriesSend(listnumberOfInquiries);
    objectData.platform_followers_number = handleDataPlatformFollowersNumberSend(objectData.platform_followers_number);
    objectData.social_platforms = handleDataSocialPlatformsSend(objectData.social_platforms);
    objectData.advertising_activity = {
        id: objectData?.advertising_activity?.id ?? null
    };
    objectData.give_away_related = !objectData.give_away_related?.id ? null : objectData.give_away_related.isBoolean;
    objectData.pdca_file_report = {
        id: objectData.pdca_file_report?.id ?? null
    };
    objectData.cashback_file_report = {
        id: objectData.cashback_file_report?.id ?? null
    };

    return handleClearDateBeforeSend(objectData);
}

export function mapDataCampaignsCopy(
    formData,
    configKeyword,
    listItemPersons,
    listDataPrize,
    purchase_conditions_data,
    contact_informations_data,
    listCategoriesSub,
    subDataCustomerTeamPic
) {
    let objectData = JSON.parse(JSON.stringify(formData));
    if (objectData.associated_campaign) {
        objectData.associated_campaign = {
            id: objectData.associated_campaign?.id ?? null
        };
    }
    objectData.keywords = configKeyword.arrayTag;
    let purchase_conditions = [];
    objectData.purchase_conditions.forEach((item) => {
        purchase_conditions.push({
            master_id: item,
            note: {
                detail: purchase_conditions_data ? purchase_conditions_data[item] : ''
            }
        });
    });
    let contact_informations = [];
    objectData.contact_informations.forEach((item) => {
        contact_informations.push({
            type_id: item,
            extension: {
                content: contact_informations_data ? contact_informations_data[item] : ''
            }
        });
    });
    let part_time_employees = [];
    objectData.part_time_employees.forEach((item) => {
        if (item.id) part_time_employees.push(item.id);
    });
    let responsible_sub_persons = [];
    listItemPersons.forEach((x, idx) => {
        if (x.id) {
            responsible_sub_persons.push({
                user_id: x.id,
                sub_person_position: idx + 1
            });
        }
    });
    objectData.purchase_conditions = purchase_conditions;
    objectData.contact_informations = contact_informations;
    objectData.responsible_sub_persons = responsible_sub_persons;

    objectData.clients = objectData.clients.map((cl) => {
        return {
            client_id: cl?.client?.id ?? null,
            client_pic_id: cl?.client_pic?.id ?? null
        };
    });

    objectData.prizes = listDataPrize;
    objectData.part_time_employees = part_time_employees;
    objectData.categories = JSON.parse(JSON.stringify(listCategoriesSub));
    objectData.categories.forEach((item) => {
        item.hashtag = item?.configHashTag?.arrayTag ?? [];
        delete item.listCondition1;
        delete item.listCondition2;
    });
    delete objectData.updated_by;
    if (objectData.campaign_start_date_y && objectData.campaign_start_date_m && objectData.campaign_start_date_d) {
        objectData.campaign_start_date =
            objectData.campaign_start_date_y + '-' + objectData.campaign_start_date_m + '-' + objectData.campaign_start_date_d;
    }
    if (objectData.campaign_end_date_y && objectData.campaign_end_date_m && objectData.campaign_end_date_d) {
        objectData.campaign_end_date = objectData.campaign_end_date_y + '-' + objectData.campaign_end_date_m + '-' + objectData.campaign_end_date_d;
    }
    if (objectData.secretariat_start_date_y && objectData.secretariat_start_date_m && objectData.secretariat_start_date_d) {
        objectData.secretariat_start_date =
            objectData.secretariat_start_date_y + '-' + objectData.secretariat_start_date_m + '-' + objectData.secretariat_start_date_d;
    }
    if (objectData.secretariat_end_date_y && objectData.secretariat_end_date_m && objectData.secretariat_end_date_d) {
        objectData.secretariat_end_date =
            objectData.secretariat_end_date_y + '-' + objectData.secretariat_end_date_m + '-' + objectData.secretariat_end_date_d;
    }
    if (objectData.receipt_expiration_date_y && objectData.receipt_expiration_date_m && objectData.receipt_expiration_date_d) {
        objectData.receipt_expiration_date =
            objectData.receipt_expiration_date_y + '-' + objectData.receipt_expiration_date_m + '-' + objectData.receipt_expiration_date_d;
    }

    if (objectData?.client_order_source?.id) {
        objectData.client_order_source = {
            id: objectData?.client_order_source?.id
        };
    }

    objectData.campaign_master_order_scopes = objectData.campaign_master_order_scopes.map((x) => {
        return {
            large_item: x.large_item.id,
            medium_items: x.medium_items?.map((y) => {
                return {
                    medium_item: y.medium_item.id,
                    small_items: y.small_items?.map((z) => {
                        return {
                            small_item: z.small_item.id,
                            detail_items: z.detail_items?.map((t) => t.detail_item.id)
                        };
                    })
                };
            })
        };
    });

    objectData.campaign_master_order_scope_partner = objectData.campaign_master_order_scope_partner.map((itemPartner) => {
        return {
            master_order_scope_id: itemPartner?.master_order_scope?.id ?? null,
            tool_id: itemPartner?.tool?.id ?? null,
            partner_id: itemPartner?.partner?.id ?? null
        };
    });
    objectData.customer_sub_person = subDataCustomerTeamPic[0] !== null ? subDataCustomerTeamPic.map((item) => item.id) : [];
    objectData.information_ips = objectData.information_ips[0]?.master_id == null ? [] : handleDataIPSend(objectData.information_ips);
    objectData.sponsor_informations =
        objectData.sponsor_informations[0]?.master_id == null ? [] : handleDataSponsorSend(objectData.sponsor_informations);

    objectData.has_tel_phone_setting = handleCheckConditionTel(objectData.campaign_master_order_scopes, objectData.has_tel_phone_setting);
    objectData.target_product_lists = handleDataTargetProductSend(objectData.target_product_lists);
    objectData.number_of_applications = handleDataNumberOfApplicationsSend(objectData.number_of_applications);
    objectData.number_of_inquiries = handleDataNumberOfInquiriesSend(objectData.number_of_inquiries);
    objectData.platform_followers_number = handleDataPlatformFollowersNumberSend(objectData.platform_followers_number);

    objectData.social_platforms = handleDataSocialPlatformsSend(objectData.social_platforms);
    objectData.advertising_activity = {
        id: objectData?.advertising_activity?.id ?? null
    };
    objectData.give_away_related = !objectData.give_away_related?.id ? null : objectData.give_away_related.isBoolean;
    objectData.pdca_file_report = {
        id: objectData.pdca_file_report?.id ?? null
    };
    objectData.cashback_file_report = {
        id: objectData.cashback_file_report?.id ?? null
    };

    return objectData;
}
