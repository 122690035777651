import Swal from 'sweetalert2';
import i18n from '@/i18n';

function checkPermission(role) {
    const user = JSON.parse(localStorage.getItem('user'));
    let roleUser = user.role;
    let permission = roleUser.permissions;
    if (Array.isArray(role)) {
        let mapNameRole = permission.map((item) => item.name);
        const result = role.some((rl) => mapNameRole.includes(rl));
        return result;
    } else {
        let index = permission.findIndex((x) => {
            return x.name === role;
        });
        if (index >= 0) {
            return true;
        } else {
            return false;
        }
    }
}

//管理者 quan ly  administrator
//営業 kinh doanh sale
//ディレクション huong dan instructor
//parter
//part_time
function checkPermissionName(listRole) {
    const user = JSON.parse(localStorage.getItem('user'));
    let role = user.role ? user.role.name : '';
    if (listRole.includes(role)) {
        return true;
    } else {
        return false;
    }
}

function isValidDate(dateString) {
    // Kiểm tra định dạng ngày tháng năm (DD/MM/YYYY)
    const dateRegex = /^\d{1,2}\/\d{1,2}\/\d{4}$/;

    if (!dateRegex.test(dateString)) {
        // Nếu không đúng định dạng, trả về false
        return false;
    }

    // Chuyển đổi chuỗi thành đối tượng Date
    const parts = dateString.split('/');
    const day = parseInt(parts[0], 10);
    const month = parseInt(parts[1], 10);
    const year = parseInt(parts[2], 10);

    // Tạo đối tượng Date và kiểm tra ngày có tồn tại không
    const dateObject = new Date(year, month - 1, day); // month - 1 vì tháng trong JavaScript bắt đầu từ 0

    // Kiểm tra xem ngày có tồn tại trong tháng không
    if (dateObject.getFullYear() === year && dateObject.getMonth() === month - 1 && dateObject.getDate() === day) {
        // Kiểm tra xem ngày có hợp lệ trong tháng không
        const lastDayOfMonth = new Date(year, month, 0).getDate();
        return day >= 1 && day <= lastDayOfMonth;
    }

    return false;
}

function onHandleShowConfirm(configConfirm) {
    return Swal.fire({
        title: configConfirm?.title ?? ``,
        text: configConfirm?.text ?? i18n.t('confirmCommon'),
        icon: configConfirm?.icon ?? 'warning',
        allowOutsideClick: configConfirm?.allowOutsideClick ?? true,
        showCancelButton: configConfirm?.showCancelButton ?? true,
        confirmButtonColor: configConfirm?.confirmButtonColor ?? '',
        cancelButtonColor: configConfirm?.cancelButtonColor ?? '#eff2f7',
        confirmButtonText: configConfirm?.confirmButtonText ?? `${i18n.t('btn.ok')}`,
        cancelButtonText: configConfirm?.cancelButtonText ?? `<div style="color: #212529;padding: 5px !important;">${i18n.t('btn.cancel')}</div>`
    }).then((result) => {
        return result.value ? true : false;
    });
}

export { checkPermission, checkPermissionName, isValidDate, onHandleShowConfirm };
